import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })

export class UserAccessibilityService {
    constructor(private http: HttpClient){}

  setUserAccessibilty(payload) {
    let url = environment.apiUrl + '/users/update/accessibility';
    return this.http.patch(url, payload).pipe(catchError(this.handelError), tap((resData)=>{}));
  }

  getUserNameAvailability(userName) {
    let url = environment.apiUrl + '/auth/check-username/' + userName;
    return this.http.get(url).pipe(catchError(this.handelError), tap((resData)=>{}));
  }

  private handelError(errorRes: HttpErrorResponse) {
    let errorMessage = '';
    if (!errorRes.error || !errorRes.error.message) {
      return throwError(errorRes.error.message);
    }
    return throwError(errorRes.error);
  }
}

